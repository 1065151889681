import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function Section7() {
  const productDetail = useSelector((state) => state.users.productDetail);
  useEffect(() => {}, [productDetail]);

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const navigate = useNavigate();
  const sliderRef = useRef(null);

  const goToNextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const goToPrevSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };
  return (
    <Root>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 pt-4">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h2>Our Best Sellers</h2>
              <div style={{ padding: "20px 0" }}>
                <button onClick={goToPrevSlide}>
                  <IoIosArrowBack />
                </button>
                <button onClick={goToNextSlide}>
                  <IoIosArrowForward />
                </button>
              </div>
            </div>
            <Slider ref={sliderRef} {...settings}>
              {productDetail?.length > 0 ? (
                productDetail.map((i, index) => (
                  <div
                    key={index}
                    className="slide-item"
                    style={{ margin: "0 10px", cursor: "pointer" }}
                    onClick={() => navigate("/engagementring")}
                  >
                    <img
                      src={i?.images?.edges?.[0]?.node?.originalSrc}
                      alt={i?.handle}
                    />
                    <div className="mainn">
                      <h5 className="prd_name m-0">{i?.title}</h5>
                      <p className="prd_price m-0">
                      {i?.currency}:
                        {i?.minPrice}{" "}-
                        {i?.maxPrice}
                      </p>
                    </div>
                  </div>
                ))
              ) : (
                <div>No Products Available</div>
              )}
            </Slider>
          </div>
        </div>
      </div>
    </Root>
  );
}
const Root = styled.section`
  margin: 20px 0px;
  display: none;
  .btn {
    margin-top: 10px;
    font-size: 18px;
    padding: 18px 34px;
    border-radius: 50px;
    line-height: 1.25;
    background-color: #000;
    color: #fff;
    border: 1px solid #000;
    &:hover {
      border: 1px solid #ededed;
      color: #000;
      background-color: #ededed;
      transition-duration: 0.5s;
    }
  }
  .d_flex {
    padding: 10px !important;
  }
  .slide-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 25vw;
    height: auto;
    text-align: center;
    img {
      width: 23vw;
      height: auto;
      border: 1px solid #ededed;
      border-radius: 15px;
      object-fit: contain;
    }
  }

  .mainn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    width: 23vw;
  }

  .d_flex {
    display: flex !important;
    padding: 10px !important;
  }

  h2 {
    padding: 20px 0px;
    text-transform: uppercase;
  }
  button {
    background-color: #fff;
    border: none;
    svg {
      font-size: 30px;
    }
  }
  img {
    height: auto;
    object-fit: contain;
  }

  .prd_name {
    font-size: 14px;
  }
  .prd_price {
    font-size: 14px;
  }

  @media (max-width: 567px) {
    .slide-item img ,.slide-item .mainn{
      width: 90vw;
    }
    h2 {
      font-size: 20px;
    }
  }
`;
