import React, { useEffect, useState } from "react";
import styled from "styled-components";
import court from "../../Images/court.jpg";
import paris from "../../Images/paris.jpg";
import flate from "../../Images/flat-court.jpg";
import dshape from "../../Images/d-shape.jpg";
import bevelled from "../../Images/bevelled-edge.jpg";
import roundedflat from "../../Images/rounded-flat.jpg";
import cushionn from "../../Images/cushion.jpg";
import productimage from "../../Images/rounded-flat.jpg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { EXCHANGE_URLS } from "../URLS";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// const sliderSettings = {
//   dots: true,
//   infinite: true,
//   speed: 500,
//   slidesToShow: 1,
//   slidesToScroll: 1,
//   arrows: false,
//   autoplay: true,
//   autoplaySpeed: 3000,
// };

const profiles = [
  { value: "court", name: "Court", image: court },
  { value: "flatcourt", name: "Flat", image: flate },
  { value: "dshape", name: "D-shape", image: dshape },
  { value: "bevellededge", name: "Bevelled Edge", image: bevelled },
  { value: "roundedflat", name: "Rounded Flat", image: roundedflat },
  { value: "paris", name: "Paris", image: paris },
  { value: "diamondcushion", name: "Cushion", image: cushionn },
];

const metalGroups = {
  "White Gold": ["9ctWhiteGold", "18ctWhiteGold"],
  "Yellow Gold": ["9ctYellowGold", "18ctYellowGold"],
  "Rose Gold": ["9ctRed", "18ctRed"],
  // "Rose Gold": ["18ctRed",  "18ctRed"],
};
const PlainSet = () => {
  const [filterOpen, setFilterOpen] = useState(true);
  const [selectedProfile, setSelectedProfile] = useState(
    "diamondplanalgorithm"
  );
  const [selectedMetal, setSelectedMetal] = useState([]); // Initialize as an array
  const [products, setProducts] = useState([]); // Store fetched products
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true);

        let selectedTags = [];
        if (selectedProfile) selectedTags.push(selectedProfile);
        if (selectedMetal.length > 0) selectedTags.push(...selectedMetal);

        let url = `${EXCHANGE_URLS}/weddingRingsdata2`;
        if (selectedTags.length > 0) {
          url += `?tags=${selectedTags.join(",")}`;
        }

        const response = await axios.get(url);
        console.log("API Response:", response.data); // Debugging API response

        if (response.data?.success) {
          setProducts(response.data.products || []);
        } else {
          setProducts([]);
          setError("No products found.");
        }
      } catch (err) {
        setError("Failed to load products. Please try again.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [selectedProfile, selectedMetal]);
  const resetFilters = () => {
    setSelectedProfile("diamondplanalgorithm");
    setSelectedMetal([]);
  };

  const handleNavigation = (product) => {
    navigate(`/more-plain-set`, {
      state: {
        selectedProfile: profiles.find((p) => p.value === selectedProfile)
          ?.name,
        selectedMetal,
        product,
      },
    });
  };

  const handleMetalGroupSelection = (group) => {
    const metals = metalGroups[group];
    setSelectedMetal(metals);
  };

  return (
    <Container>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "20px",
        }}
      >
        {/* <h5>WEDDING RING</h5> */}
        <Plaindiv>
          <h5>Plain Bands</h5>
          <img src={productimage} alt="plain" width="100" />
        </Plaindiv>
        <FilterButton onClick={() => setFilterOpen(!filterOpen)}>
          {filterOpen ? "Hide Filters" : "Show Filters"}
        </FilterButton>
      </div>

      <FilterSection open={filterOpen}>
        <ProfileSelection>
          {profiles.map((profile) => (
            <Profile
              key={profile.value} // Use value as key
              selected={selectedProfile === profile.value}
              onClick={() => {
                setSelectedProfile(profile.value);
                setSelectedMetal(metalGroups["White Gold"]); // Auto-select White Gold
              }}
            >
              <img src={profile.image} alt={profile.name} />
              <span>{profile.name}</span>
            </Profile>
          ))}
        </ProfileSelection>

        <h5>Metal Colour</h5>
        <MetalColors>
          {Object.keys(metalGroups).map((group) => (
            <div
              key={group}
              onClick={() => handleMetalGroupSelection(group)}
              style={{
                width: "20px",
                height: "20px",
                borderRadius: "50%",
                backgroundColor:
                  group === "White Gold"
                    ? "#E0E0E0"
                    : group === "Yellow Gold"
                    ? "#FFD700"
                    : "#F5E1DA",
                border: selectedMetal.some((m) =>
                  metalGroups[group].includes(m)
                )
                  ? "2px solid black"
                  : "none",
                cursor: "pointer",
              }}
            />
          ))}
        </MetalColors>

        <Filters>
          {selectedProfile && (
            <FilterTag>
              {selectedProfile}
              <CloseButton onClick={() => setSelectedProfile(null)}>
                &times;
              </CloseButton>
            </FilterTag>
          )}
          {selectedMetal.length > 0 &&
            selectedMetal.map((metal) => (
              <FilterTag key={metal}>
                {metal}
                <CloseButton
                  onClick={() =>
                    setSelectedMetal(selectedMetal.filter((m) => m !== metal))
                  }
                >
                  &times;
                </CloseButton>
              </FilterTag>
            ))}
          <ResetButton onClick={resetFilters}>Reset All</ResetButton>
        </Filters>
      </FilterSection>

      <ProductList>
        {products.length > 0 ? (
          products.map((product, index) => (
            <ProductCard key={index}>
              {product.images?.edges?.length > 0 ? (
                <img
                  src={product.images.edges[0].node.originalSrc}
                  alt={product.title}
                  width="150"
                  height="150"
                />
              ) : (
                <p>No Image Available</p>
              )}
              <h4>
                <div>{product.title}</div>{" "}
                <div>
                  {product.priceRange?.minVariantPrice?.currencyCode}
                  {product.priceRange?.minVariantPrice?.amount}
                </div>
              </h4>
              <ProductActions className="actions">
                <MoreInfoButton onClick={() => handleNavigation(product)}>
                  More Info
                </MoreInfoButton>
                <AddToCartButton onClick={() => handleNavigation(product)}>
                  Add to Cart
                </AddToCartButton>
              </ProductActions>
            </ProductCard>
          ))
        ) : (
          <p>No products found.</p>
        )}
      </ProductList>
    </Container>
  );
};

export default PlainSet;

// Styled Components
export const Container = styled.div`
  padding: 20px;
  margin: 20px;
  text-align: center;
  h5,
  h4,
  h3 {
    text-align: center;
  }
  h4 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  @media (max-width: 768px) {
    padding: 10px;
  }

  .slick-dots {
    bottom: 10px !important;
  }

  .slick-dots li button:before {
    color: white !important;
    opacity: 0.75 !important;
  }

  .slick-dots li.slick-active button:before {
    color: #fff !important;
    opacity: 1 !important;
  }
`;

export const FilterButton = styled.button`
  text-align: center;
  background: #333;
  color: white;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  margin-bottom: 10px;
`;

export const FilterSection = styled.div`
  display: ${(props) => (props.open ? "block" : "none")};
  padding: 15px;
  margin-bottom: 20px;
`;

export const Filters = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
`;

export const FilterTag = styled.span`
  background-color: rgb(247, 247, 247);
  border-radius: 2px;
  border: 1px solid rgb(247, 247, 247);
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 4px 15px;
`;

export const ProfileSelection = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`;
export const Plaindiv = styled.div`
  margin: 20px 0px;
  text-align: center;
`;

export const Profile = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  flex-wrap: wrap;
  border: ${(props) => (props.selected ? "2px solid #000" : "transparent")};
  &:hover span {
    display: block;
  }

  span {
    /* display: none; */
    /* position: absolute;
    top: -15px; */
    /* box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); */
    color: black;
    text-align: center;
    background: white;
    padding: 5px 8px;
    border-radius: 5px;
    white-space: nowrap;
    font-size: 12px;
    line-height: 25px;
    font-weight: 550;
    width: 93px !important;
  }
  img {
    width: 80px;
    height: auto;
    margin: 10px 20px;
  }
`;

export const MetalColors = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
  margin: 0px 0px 30px;
  padding: 10px 0px 20px;
`;

export const MetalOption = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: ${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: ${(props) => (props.selected ? "2px solid black" : "none")};
  position: relative;
  margin: 10px;
  span {
    width: 100px;
    text-align: center;
    padding: 10px;
    position: absolute;
    top: -50px;
    font-size: 12px;
    color: black;
  }
`;

export const ProductList = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 25px;
  padding: 20px 0;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 576px) {
    grid-template-columns: 1fr;
  }
`;
export const ProductCard = styled.div`
  text-align: center;
  padding: 20px;
  border: 1px solid #eee;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  background: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);

    .actions {
      opacity: 1;
      transform: translateY(0);
    }
  }
  &:hover {
    transform: scale(1.05); /* Slightly enlarge */
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    z-index: 10;
  }

  &:hover::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    z-index: -1;
    border-radius: 12px;
    border: 3px solid #000;
  }
  h4 {
    margin: 15px 0;
    font-size: 1.1rem;
    color: #333;
    font-weight: 600;
  }
`;
export const ProductActions = styled.div`
  display: flex;
  /* flex-direction: column; */
  gap: 12px;
  position: absolute;
  bottom: 20px;
  left: 10%;
  transform: translateX(-50%);
  width: 80%;
  opacity: 0;
  transition: all 0.3s ease;
  transform: translateY(20px);
`;
export const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-left: 8px;
  font-size: 16px;
  color: #666;
  &:hover {
    color: #000;
  }
`;
export const MoreInfoButton = styled.button`
  background: white;
  border: 1px solid black;
  border-radius: 25px;
  padding: 5px 10px;
  cursor: pointer;
  width: 100%;
`;
export const AddToCartButton = styled.button`
  background: black;
  border-radius: 25px;

  border: 1px solid transparent;
  color: white;
  padding: 5px 10px;
  cursor: pointer;
  width: 100%;
`;
export const ResetButton = styled.button`
  background: none;
  border: 1px solid #333;
  color: #333;
  padding: 5px 10px;
  border-radius: 10px;
  cursor: pointer;
  margin-left: auto;
  &:hover {
    background: #333;
    color: white;
  }
`;
