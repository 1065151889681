import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  AddToCartButton,
  CloseButton,
  Container,
  FilterButton,
  Filters,
  FilterSection,
  FilterTag,
  MetalColors,
  MoreInfoButton,
  Plaindiv,
  ProductActions,
  ProductCard,
  ProductList,
  Profile,
  ProfileSelection,
  ResetButton,
} from "./PlainSet";
import { EXCHANGE_URLS } from "../URLS";

import court from "../../Images/court.jpg";
import flate from "../../Images/flat-court.jpg";
import channelset from "../../Images/channel-set.jpg";
import clawset from "../../Images/claw-set.jpg";
import grainset from "../../Images/grain-set.jpg";
import princesset from "../../Images/princess-set.jpg";
import roundset from "../../Images/round-set.jpg";
import productimage from "../../Images/channel-set.jpg";

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const stone = [
  { value: "weddingRingRounds", name: "Round", image: roundset },
  { value: "weddingRingPrincess", name: "Princess", image: princesset },
];

const metalGroups = {
  "White Gold": ["9ctWhiteGold", "18ctWhiteGold"],
  "Yellow Gold": ["9ctYellowGold", "18ctYellowGold"],
  "Rose Gold": ["9ctRed", "18ctRed"],
};

const DiamondSetCarat = () => {
  const [filterOpen, setFilterOpen] = useState(true);
  const [selectedMetal, setSelectedMetal] = useState(["9ctWhiteGold", "18ctWhiteGold"]);
  const [selectedStone, setSelectedStone] = useState("weddingRingRounds");
    const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true);

        let selectedTags = [];
        if (selectedStone) selectedTags.push(selectedStone);
        if (selectedMetal.length > 0) selectedTags.push(...selectedMetal);

        let url = `${EXCHANGE_URLS}/weddingRingsdata`;
        if (selectedTags.length > 0) {
          url += `?tags=${selectedTags.join(",")}`;
        }

        const response = await axios.get(url);
        console.log("API Response:", response.data); // Debugging API response

        if (response.data?.success) {
          setProducts(response.data.products || []);
        } else {
          setProducts([]);
          setError("No products found.");
        }
      } catch (err) {
        setError("Failed to load products. Please try again.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [selectedMetal, selectedStone]);

  const resetFilters = () => {
    setSelectedMetal(["9ctWhiteGold", "18ctWhiteGold"]);
    setSelectedStone("weddingRingRounds");
  };
  

  const handleNavigation = (product) => {
    navigate(`/more-diamond-set-carat`, {
      state: { selectedMetal, selectedStone, product },
    });
  };

  const handleMetalGroupSelection = (group) => {
    const metals = metalGroups[group];
    setSelectedMetal(metals); // Selecting only the clicked group, deselecting others
  };
  
  return (
    <Container>
       <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "20px",
              }}
            >
              {/* <h5>WEDDING RING</h5> */}
              <Plaindiv>
        <h5>Diamond Set By Carat Weight</h5>
        <img src={productimage} alt="plain" width="100" />
      </Plaindiv>
      <FilterButton onClick={() => setFilterOpen(!filterOpen)}>
        {filterOpen ? "Hide Filters" : "Show Filters"}
      </FilterButton>
            </div>
     

    

      <FilterSection open={filterOpen}>
        

        <ProfileSelection>
          {stone.map((s) => (
            <Profile key={s.value} selected={selectedStone === s.value} onClick={() => setSelectedStone(s.value)}>
              <img src={s.image} alt={s.name} />
              <span>{s.name}</span>
            </Profile>
          ))}
        </ProfileSelection>

        <h5>Metal Colour</h5>
        <MetalColors>
          {Object.keys(metalGroups).map((group) => (
            <div
              key={group}
              onClick={() => handleMetalGroupSelection(group)}
              style={{
                width: "20px",
                height: "20px",
                borderRadius: "50%",
                backgroundColor:
                  group === "White Gold" ? "#E0E0E0" : group === "Yellow Gold" ? "#FFD700" : "#F5E1DA",
                border: selectedMetal.some((m) => metalGroups[group].includes(m)) ? "2px solid black" : "none",
                cursor: "pointer",
              }}
            />
          ))}
        </MetalColors>

        <Filters>
          {selectedStone && (
            <FilterTag>
              {selectedStone}
              <CloseButton onClick={() => setSelectedStone(null)}>&times;</CloseButton>
            </FilterTag>
          )}
          {selectedMetal.length > 0 &&
            selectedMetal.map((metal) => (
              <FilterTag key={metal}>
                {metal}
                <CloseButton onClick={() => setSelectedMetal(selectedMetal.filter((m) => m !== metal))}>
                  &times;
                </CloseButton>
              </FilterTag>
            ))}
          <ResetButton onClick={resetFilters}>Reset All</ResetButton>
        </Filters>
      </FilterSection>

      <ProductList>
        {products.length > 0 ? (
          products.map((product, index) => (
            <ProductCard key={index}>
              {product.images?.edges?.length > 0 ? (
                <img
                  src={product.images.edges[0].node.originalSrc}
                  alt={product.title}
                  width="150"
                  height="150"
                />
              ) : (
                <p>No Image Available</p>
              )}
               <h4>
                <div>{product.title}</div>{" "}
                <div>
                  {product.priceRange?.minVariantPrice?.currencyCode}
                  {product.priceRange?.minVariantPrice?.amount}
                </div>
              </h4>
              <ProductActions className="actions">
                 <MoreInfoButton onClick={() => handleNavigation(product)}>
                   More Info
                 </MoreInfoButton>
                 <AddToCartButton onClick={() => handleNavigation(product)}>
                   Add to Cart
                 </AddToCartButton>
               </ProductActions>
            </ProductCard>
          ))
        ) : (
          <p>No products found.</p>
        )}
      </ProductList>

    </Container>
  );
};

export default DiamondSetCarat;
