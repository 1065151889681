import React, { useEffect, useState } from "react";
import court from "../../Images/court.jpg";
import flate from "../../Images/flat-court.jpg";
import channelset from "../../Images/channel-set.jpg";
import clawset from "../../Images/claw-set.jpg";
import grainset from "../../Images/grain-set.jpg";
import princesset from "../../Images/princess-set.jpg";
import roundset from "../../Images/round-set.jpg";
import productimage from "../../Images/channel-set.jpg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { EXCHANGE_URLS } from "../URLS";
import {
  AddToCartButton,
  CloseButton,
  Container,
  FilterButton,
  Filters,
  FilterSection,
  FilterTag,
  MetalColors,
  MoreInfoButton,
  Plaindiv,
  ProductActions,
  ProductCard,
  ProductList,
  Profile,
  ProfileSelection,
  ResetButton,
} from "./PlainSet";

// Profile Options
const profiles = [
  { value: "Coveragecourt", name: "Court", image: court },
  { value: "Coverageflatcourt", name: "Flat", image: flate },
];

// Setting Options
const setting = [
  { value: "channelset", name: "Channel Set", image: channelset },
  { value: "clawset", name: "Claw Set", image: clawset },
  { value: "grainset", name: "Grain Set", image: grainset },
];

// Stone Options
const stone = [
  { value: "CoverageRound", name: "Round", image: roundset },
  { value: "CoveragePrincess", name: "Princess", image: princesset },
];

// Metal Groups
const metalGroups = {
  "White Gold": ["9ctWhiteGold", "18ctWhiteGold"],
  "Yellow Gold": ["14ctYellowGold", "18ctYellowGold"],
  "Rose Gold": ["14ctRoseGold", "18ctRoseGold"],
};

const DiamondSet = () => {
  const [filterOpen, setFilterOpen] = useState(true);
  const [selectedProfile, setSelectedProfile] = useState(
    "diamondplanalgorithm"
  );
  const [selectedMetal, setSelectedMetal] = useState([]);
  const [selectedSetting, setSelectedSetting] = useState(null);
  const [selectedStone, setSelectedStone] = useState(null);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true);

        const selectedTags = [
          selectedProfile,
          ...selectedMetal,
          selectedSetting,
          selectedStone,
        ].filter(Boolean);

        let url = `${EXCHANGE_URLS}/weddingRingsdata1`;
        if (selectedTags.length > 0) {
          url += `?tags=${selectedTags.join(",")}`;
        }

        const response = await axios.get(url);
        console.log("API Response:", response.data);

        if (response.data?.success) {
          setProducts(response.data.products || []);
        } else {
          setProducts([]);
          setError("No products found.");
        }
      } catch (err) {
        setError("Failed to load products. Please try again.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [selectedProfile, selectedMetal, selectedSetting, selectedStone]);

  const resetFilters = () => {
    setSelectedProfile("diamondplanalgorithm");
    setSelectedMetal([]);
    setSelectedSetting(null);
    setSelectedStone(null);
  };

  const handleNavigation = (product) => {
    navigate(`/more-diamond-set`, {
      state: {
        selectedProfile,
        selectedMetal,
        selectedSetting,
        selectedStone,
        product,
      },
    });
  };

  const handleMetalGroupSelection = (group) => {
    const metals = metalGroups[group];
    setSelectedMetal(metals); // Selecting only the clicked group, deselecting others
  };

  return (
    <Container>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "20px",
        }}
      >
        {/* <h5>WEDDING RING</h5> */}
        <Plaindiv>
          <h5>Diamond Set Bands</h5>
          <img src={productimage} alt="plain" width="100" />
        </Plaindiv>
        <FilterButton onClick={() => setFilterOpen(!filterOpen)}>
          {filterOpen ? "Hide Filters" : "Show Filters"}
        </FilterButton>
      </div>

        <ProfileSelection>
          {profiles.map((p) => (
            <Profile
              key={p.value}
              selected={selectedProfile === p.value}
              onClick={() => setSelectedProfile(p.value)}
            >
              <img src={p.image} alt={p.name} />
              <span>{p.name}</span>
            </Profile>
          ))}
        </ProfileSelection>
      <FilterSection open={filterOpen}>
       

        

        {/* Select a Setting Style */}
        <h5>Select a Setting Style</h5>
        <ProfileSelection>
          {setting.map((s) => (
            <Profile
              key={s.value}
              selected={selectedSetting === s.value}
              onClick={() => setSelectedSetting(s.value)}
            >
              <img src={s.image} alt={s.value} />
              <span>{s.name}</span>
            </Profile>
          ))}
        </ProfileSelection>

        {/* Select a Stone Shape */}
        <h5>Select a Stone Shape</h5>
        <ProfileSelection>
          {stone.map((s) => (
            <Profile
              key={s.value}
              selected={selectedStone === s.value}
              onClick={() => setSelectedStone(s.value)}
            >
              <img src={s.image} alt={s.value} />
              <span>{s.name}</span>
            </Profile>
          ))}
        </ProfileSelection>

        {/* Metal Colour Selection */}
        <h5>Metal Colour</h5>
        <MetalColors>
          {Object.keys(metalGroups).map((group) => (
            <div
              key={group}
              onClick={() => handleMetalGroupSelection(group)}
              style={{
                width: "20px",
                height: "20px",
                borderRadius: "50%",
                backgroundColor:
                  group === "White Gold"
                    ? "#E0E0E0"
                    : group === "Yellow Gold"
                    ? "#FFD700"
                    : "#F5E1DA",
                border: selectedMetal.some((m) =>
                  metalGroups[group].includes(m)
                )
                  ? "2px solid black"
                  : "none",
                cursor: "pointer",
              }}
            />
          ))}
        </MetalColors>
        <Filters>
          {selectedSetting && (
            <FilterTag>
              {selectedSetting}
              <CloseButton onClick={() => setSelectedSetting(null)}>
                &times;
              </CloseButton>
            </FilterTag>
          )}
          {selectedStone && (
            <FilterTag>
              {selectedStone}
              <CloseButton onClick={() => setSelectedStone(null)}>
                &times;
              </CloseButton>
            </FilterTag>
          )}
          {selectedProfile && (
            <FilterTag>
              {selectedProfile}
              <CloseButton onClick={() => setSelectedProfile(null)}>
                &times;
              </CloseButton>
            </FilterTag>
          )}
          {selectedMetal.length > 0 &&
            selectedMetal.map((metal) => (
              <FilterTag key={metal}>
                {metal}
                <CloseButton
                  onClick={() =>
                    setSelectedMetal(selectedMetal.filter((m) => m !== metal))
                  }
                >
                  &times;
                </CloseButton>
              </FilterTag>
            ))}
          <ResetButton onClick={resetFilters}>Reset All</ResetButton>
        </Filters>

      </FilterSection>
      <ProductList>
        {products.length > 0 ? (
          products.map((product, index) => (
            <ProductCard key={index}>
              {product.images?.edges?.length > 0 ? (
                <img
                  src={product.images.edges[0].node.originalSrc}
                  alt={product.title}
                  width="150"
                  height="150"
                />
              ) : (
                <p>No Image Available</p>
              )}
              <h4>
                <div>{product.title}</div>{" "}
                <div>
                  {product.priceRange?.minVariantPrice?.currencyCode}
                  {product.priceRange?.minVariantPrice?.amount}
                </div>
              </h4>
              <ProductActions className="actions">
                <MoreInfoButton onClick={() => handleNavigation(product)}>
                  More Info
                </MoreInfoButton>
                <AddToCartButton onClick={() => handleNavigation(product)}>
                  Add to Cart
                </AddToCartButton>
              </ProductActions>
            </ProductCard>
          ))
        ) : (
          <p>No products found.</p>
        )}
      </ProductList>
    </Container>
  );
};

export default DiamondSet;
