import { EXCHANGE_URLS_WEDDING } from "../URLS";
import React, { useEffect, useState } from "react";
import axios from "axios";
import aeroplane from "../../Images/aeroplane.png";
import badgess from "../../Images/badgess.png";
import moneyinhand from "../../Images/moneyinhand.png";
import certifiedd from "../../Images/certifiedd.png";
import Drawer from "react-modern-drawer";
import wid from "../../Images/svgviewer-png-output.png";
import met from "../../Images/svgviewer-png-output (3).png";
import circle from "../../Images/Screenshot from 2024-07-25 17-29-15.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useLoading } from "../LoadingContext";
import noimg from "../../Images/eligantPacking.png";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ringwithdiamond from "../../Images/ringwithdiamond.png";
import ship from "../../Images/ship.png";
import ret from "../../Images/ret.png";
import { ImageContainer, Root } from "../WeddingBands/MoreForHerBand";

const caratList = [
  { value: "0.25CT" },
  { value: "0.33CT" },
  { value: "0.50CT" },
  { value: "0.75CT" },
  { value: "1.00CT" },
  { value: "1.25CT" },
  { value: "1.50CT" },
];

const metalOptions = {
  white: [
    { type: "9ct White Gold" },
    { type: "18ct White Gold" },
    { type: "Platinum" },
  ],
  yellow: [{ type: "9ct Yellow Gold" }, { type: "18ct Yellow Gold" }],
  rose: [{ type: "9ct Rose Gold" }, { type: "18ct Rose Gold" }],
};

export default function MoreForDiamondCarat() {
  const [availableCarats, setAvailableCarats] = useState([]);
  const [availableMetals, setAvailableMetals] = useState([]);
  const [selectedCarat, setSelectedCarat] = useState("");
  const [selectedMetal, setSelectedMetal] = useState("");
  const [variationData, setVariationData] = useState(null);
  const [unique, setUnique] = useState(null);
  const [selectedMetalColor, setSelectedMetalColor] = useState("white");
  const [productData, setProductData] = useState(null);
  const [productData1, setProductData1] = useState(null);

  const { setLoading } = useLoading();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const { productId } = useParams();
  const location = useLocation();
  const product = location.state?.product;
  const [selectedSize, setSelectedSize] = useState("");

  const handleSizeChange = (event) => {
    setSelectedSize(event.target.value);
  };
  useEffect(() => {
    const fetchVariations = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `https://api-diamond.phanomprofessionals.com/api/rings/enternityvariation?productId=${product.id}`
        );

        const { data } = response.data;
        const carats = new Set();
        const metals = new Set();

        data.variants.edges.forEach(({ node }) => {
          node.selectedOptions.forEach((option) => {
            if (option.name === "CaratWeight") carats.add(option.value);
            if (option.name === "MetalType") metals.add(option.value);
          });
        });

        const caratArray = Array.from(carats);
        const metalArray = Array.from(metals);

        setAvailableCarats(caratArray);
        setAvailableMetals(metalArray);
        setVariationData(data);

        if (caratArray.length > 0) setSelectedCarat(caratArray[0]);
        if (metalArray.length > 0) setSelectedMetal(metalArray[0]);
      } catch (error) {
        console.error("Error fetching variations:", error);
      } finally {
        setLoading(false);
      }
    };

    if (product?.id) fetchVariations();
  }, [product?.id, setLoading]);

  useEffect(() => {
    if (variationData && (selectedCarat || selectedMetal)) {
      const selectedVariant = variationData.variants.edges.find(({ node }) => {
        const options = node.selectedOptions;
        const caratMatch = selectedCarat
          ? options.find(
              (opt) => opt.name === "CaratWeight" && opt.value === selectedCarat
            )
          : true;
        const metalMatch = selectedMetal
          ? options.find(
              (opt) => opt.name === "MetalType" && opt.value === selectedMetal
            )
          : true;

        return caratMatch && metalMatch;
      });

      if (selectedVariant) {
        setUnique({
          ...selectedVariant.node,
          price:
            selectedVariant.node.price === "0.00"
              ? "Price on request"
              : `$${selectedVariant.node.price}`,
        });
      }
    }
  }, [selectedCarat, selectedMetal, variationData]);

  useEffect(() => {
    const fetchPreDefineApi = async () => {
      if (!selectedCarat || !selectedMetalColor) return;

      setLoading(true);
      try {
        const baseUrl = `https://api-diamond.phanomprofessionals.com/api/rings/caratweight?productId=${product.id}`;
        const caratQuery = `&CaratWeight=${encodeURIComponent(selectedCarat)}`;
        const metalTypes = metalOptions[selectedMetalColor].map((m) =>
          encodeURIComponent(m.type)
        );
        const materialQuery = `&MetalType=${metalTypes.join(",")}`;
        const apiUrl = baseUrl + caratQuery + materialQuery;

        const response = await axios.get(apiUrl);
        if (response.status === 200) {
          const data = response?.data;
          setProductData(data.data[0]);
          setProductData1(data);
          console.log("profuctdata", productData1);
          if (!data?.variants?.edges) {
            console.error("Invalid API response:", data);
            setVariationData(null);
            return;
          }

          setVariationData(data);
          const selectedVariant = data.variants.edges.find(({ node }) => {
            const options = node.selectedOptions;
            return (
              options.some(
                (opt) =>
                  opt.name === "CaratWeight" && opt.value === selectedCarat
              ) &&
              options.some(
                (opt) =>
                  opt.name === "MetalType" &&
                  metalTypes.includes(encodeURIComponent(opt.value))
              )
            );
          });

          if (selectedVariant) {
            setUnique({
              ...selectedVariant.node,
              price:
                selectedVariant.node.price === "0.00"
                  ? "Price on request"
                  : `$${selectedVariant.node.price}`,
            });
          }
        }
      } catch (error) {
        console.error("Error fetching product variation:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPreDefineApi();
  }, [selectedCarat, selectedMetalColor]);
  const ringSizes = Array.from({ length: 18 }, (_, i) =>
    String.fromCharCode(72 + i)
  ); // 'H' (72 ASCII) to 'Z' (90 ASCII)


  const handleCheckout = () => {
    navigate("/checkout-plain-diamondset", {
      state: {
        carat: productData,
        ringSize: selectedSize,
      },
    });
  };

  const handleCaratClick = (carat) => {
    setSelectedCarat(carat);
  };

  const handleColorSelect = (color) => {
    setSelectedMetalColor(color);
    setSelectedMetal("");
  };

  const imageUrl = unique?.image?.originalSrc || noimg;

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <Root>
      <div className="main_wrapper">
        <div className="image_div">
          <ImageContainer>
            <img src={productData?.image?.originalSrc ?? noimg} alt="Product" />
          </ImageContainer>
        </div>

        <div className="des_div">
          <div className="title">
            <h2>{productData1?.product}</h2>
            <p>{productData1?.description}</p>
          </div>

          {availableCarats.length > 0 && (
            <div className="carat_selection">
              <h3 className="section_title">Select Diamond Carat</h3>
              <div className="carat_grid">
                {caratList.map(
                  (caratItem) =>
                    availableCarats.includes(caratItem.value) && (
                      <div
                        key={caratItem.value}
                        className={`carat_card ${
                          selectedCarat === caratItem.value ? "selected" : ""
                        }`}
                        onClick={() => handleCaratClick(caratItem.value)}
                      >
                        {/* <div className="carat_image">
                          <img src={caratItem.imgUrl} alt={caratItem.value} />
                        </div> */}
                        <div className="carat_label">
                          {caratItem.value}
                          {selectedCarat === caratItem.value && (
                            <div className="selected_indicator">✓</div>
                          )}
                        </div>
                      </div>
                    )
                )}
              </div>
            </div>
          )}

          <style jsx>{`
            .carat_selection {
              padding: 1.5rem;
              background: #fff;
              border-radius: 12px;
              box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
              margin: 1rem 0;
            }

            .section_title {
              color: #2a2d34;
              font-size: 1.25rem;
              margin-bottom: 1.5rem;
              font-weight: 600;
            }

            .carat_grid {
              display: grid;
              grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
              gap: 1rem;
            }

            .carat_card {
              border: 2px solid #e0e0e0;
              border-radius: 8px;
              padding: 1rem;
              cursor: pointer;
              transition: all 0.3s ease;
              position: relative;
              background: #f8f9fa;

              &:hover {
                transform: translateY(-2px);
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
              }
            }

            .carat_card.selected {
              border-color: #2a2d34;
              background: #fff;
              box-shadow: 0 4px 12px rgba(42, 45, 52, 0.15);

              .carat_label {
                color: #2a2d34;
                font-weight: 600;
              }
            }

            .carat_image {
              height: 80px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-bottom: 0.5rem;

              img {
                max-width: 100%;
                height: auto;
                filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
              }
            }

            .carat_label {
              text-align: center;
              font-size: 0.9rem;
              color: #666;
              position: relative;
              transition: color 0.3s ease;
            }

            .selected_indicator {
              position: absolute;
              top: -1.5rem;
              right: -0.5rem;
              background: #2a2d34;
              color: white;
              width: 24px;
              height: 24px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 0.8rem;
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
            }
          `}</style>

          <div className="metal-selection-section">
            <h3>METAL COLOUR: {selectedMetalColor.toUpperCase()}</h3>
            <div className="color-options">
              {["white", "yellow", "rose"].map((color) => (
                <button
                  key={color}
                  className={`color-option ${
                    selectedMetalColor === color ? "active" : ""
                  }`}
                  onClick={() => handleColorSelect(color)}
                  aria-label={color}
                >
                  <div
                    className="color-swatch"
                    style={{
                      backgroundColor:
                        color === "white"
                          ? "#ffffff"
                          : color === "yellow"
                          ? "#ffd700"
                          : "#b76e79",
                    }}
                  />
                </button>
              ))}
            </div>

            <table className="metal-table">
              <thead>
                <tr>
                  <th>METAL TYPE</th>
                  <th>PRICE</th>
                </tr>
              </thead>
              <tbody>
                {productData1?.data
                  ?.filter((variant) => {
                    const metalType = variant.selectedOptions.find(
                      (opt) => opt.name === "MetalType"
                    )?.value;
                    return metalOptions[selectedMetalColor].some(
                      (m) => m.type === metalType
                    );
                  })
                  ?.map((variant) => {
                    const metalType = variant.selectedOptions.find(
                      (opt) => opt.name === "MetalType"
                    )?.value;
                    const price = parseFloat(variant.price).toFixed(2);

                    return (
                      <tr
                        key={variant.id}
                        className={
                          selectedMetal === metalType ? "selected-metal" : ""
                        }
                        onClick={() => setSelectedMetal(metalType)}
                      >
                        <td>{metalType}</td>
                        <td>{price === "0.00" ? "PRICE" : `$${price}`}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <style jsx>{`
            .metal-selection-section {
              margin: 2rem 0;
              border: 1px solid #ddd;
              padding: 1rem;
              h3 {
                font-size: 15px;
                font-weight: 700;
              }
            }

            .color-options {
              display: flex;
              gap: 1rem;
              margin-bottom: 10px;
            }

            .color-option {
              padding: 4px;
              border: 2px solid transparent;
              border-radius: 50%;
              background: none;
              cursor: pointer;
              width: 40px;
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .color-option.active {
              border-color: #000;
            }

            .color-swatch {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
              border: 1px solid #ddd;
            }
            .metal-table tr.selected-metal {
              background-color: #f0f0f0;
              border: 1px solid black;
            }

            .metal-table {
              border-collapse: collapse;
            }

            .metal-table td,
            .metal-table th {
              padding: 12px;
              text-align: left;
            }

            .metal-table tr {
              transition: all 0.2s ease;
            }

            .metal-table tr:hover {
              background-color: #f8f8f8;
              cursor: pointer;
            }
          `}</style>
 <div>
            <div className="ring_size">
              <select value={selectedSize} onChange={handleSizeChange}>
                <option value="">Select Ring Size</option>
                {ringSizes.map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>
            </div>
            
          </div>

          <div className="total_price">
            <h5>Total Price</h5>
            <h2
              style={{
                padding: "8px",
                borderRadius: "4px",
              }}
            >
              {(() => {
                // Find the selected variant
                const selectedVariant = selectedMetal
                  ? productData1?.data?.find((v) =>
                      v.selectedOptions.some(
                        (opt) =>
                          opt.name === "MetalType" &&
                          opt.value === selectedMetal
                      )
                    )
                  : productData1?.data?.[0];

                // Handle price display
                if (!selectedVariant) return "Loading...";
                if (selectedVariant.price === "0.00") return "Price on request";

                return `GBP${parseFloat(selectedVariant.price).toFixed(2)}`;
              })()}
            </h2>
            <p>Ships in 2-3 weeks</p>
          </div>
          <div className="product_btn">
            <button
              className="btn"
              onClick={() => {
                toggleDrawer();
              }}
            >
              Add to Cart
            </button>
            <Drawer
              open={isOpen}
              onClose={toggleDrawer}
              direction="right"
              className="custom-drawer"
              size="300px"
            >
              <div className="cart_heading">
                <h2>My Shopping Bag</h2>
              </div>

              <div className="prod_main_div">
                <div className="prod_div">
                  <div className="prod">
                    <div
                      className="bg-img"
                      style={{
                        backgroundImage: `url(${
                          productData?.image?.originalSrc || imageUrl
                        })`,
                        backgroundSize: "100%",
                        backgroundRepeat: "center",
                      }}
                    >
                      <div className="dia_img">
                        <img
                          src={
                            productData?.image?.originalSrc || imageUrl || noimg
                          }
                          alt="img"
                        />
                      </div>
                    </div>

                    <div className="prod_name">
                      <h3>{productData?.title}</h3>
                    </div>

                    <div className="prod_spec">
                      <div className="icon_content">
                        <img
                          src={productData?.image?.originalSrc || imageUrl}
                          alt="img"
                        />
                        <div className="content_head">
                          <h4>{productData?.title}</h4>
                          {/* <p>14k White Gold </p> */}
                        </div>
                      </div>
                      <div className="prod_price">
                        <h4>
                          {" "}
                          {(() => {
                            // Find the selected variant
                            const selectedVariant = selectedMetal
                              ? productData1?.data?.find((v) =>
                                  v.selectedOptions.some(
                                    (opt) =>
                                      opt.name === "MetalType" &&
                                      opt.value === selectedMetal
                                  )
                                )
                              : productData1?.data?.[0];

                            // Handle price display
                            if (!selectedVariant) return "Loading...";
                            if (selectedVariant.price === "0.00")
                              return "Price on request";

                            return `GBP${parseFloat(
                              selectedVariant.price
                            ).toFixed(2)}`;
                          })()}
                        </h4>
                      </div>
                    </div>

                    <div className="prod_spec">
                      <div className="icon_content">
                        <div className="d-flex gap-3">
                          <h4>Width:</h4>
                          {caratList
                            .filter(
                              (caratItem) => caratItem.value === selectedCarat
                            )
                            .map((caratItem) => (
                              <div
                                key={caratItem.value}
                                className="carat_card selected"
                                onClick={() =>
                                  handleCaratClick(caratItem.value)
                                }
                              >
                                <div className="carat_label">
                                  {caratItem.value}
                                  <div className="selected_indicator">✓</div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="total_price_div">
                <p>Total:</p>
                <h4>
                  {" "}
                  {(() => {
                    // Find the selected variant
                    const selectedVariant = selectedMetal
                      ? productData1?.data?.find((v) =>
                          v.selectedOptions.some(
                            (opt) =>
                              opt.name === "MetalType" &&
                              opt.value === selectedMetal
                          )
                        )
                      : productData1?.data?.[0];

                    // Handle price display
                    if (!selectedVariant) return "Loading...";
                    if (selectedVariant.price === "0.00")
                      return "Price on request";

                    return `GBP${parseFloat(selectedVariant.price).toFixed(2)}`;
                  })()}
                </h4>
              </div>

              <div className="but_div">
                <button onClick={handleCheckout}>Checkout Now</button>
              </div>
            </Drawer>
          </div>

          <div className="policy">
            <div className="policy_type">
              <img src={aeroplane} alt="aeroplane_images" />
              <p>
                Overnight <br />
                Shipping
              </p>
            </div>

            <div className="policy_type">
              <img
                src={badgess}
                alt="badgess_images"
                style={{ width: "50px", height: "50px" }}
              />
              <p>
                Lifetime <br />
                Warranty
              </p>
            </div>

            <div className="policy_type">
              <img src={moneyinhand} alt="moneyinhand_images" />
              <p>
                30 Days <br />
                Free Return
              </p>
            </div>

            <div className="policy_type">
              <img src={certifiedd} alt="certifiedd_images" />
              <p>
                Certificate
                <br />& Appraisal
              </p>
            </div>
          </div>

          <div className="setting_detail">
            <div className="setting_main_div">
              <h4 className="seting_content">Setting Details</h4>
              <div className="subfirst_detail">
                <div className="profile_div">
                  <div className="profile_cont">
                    <img src={wid} alt="pinkimg" />
                    <p>CARAT</p>
                  </div>

                  <h4>{selectedCarat}</h4>

                  <div className="img_div" style={{ visibility: "hidden" }}>
                    <img src={circle} alt="img" />
                  </div>
                  <p className="para">Measured at the base of the ring</p>
                </div>
              </div>

              <div className="subsecond_detail">
                <div className="profile_div">
                  <div className="profile_cont">
                    <img src={met} alt="pinkimg" />
                    <p>METAL</p>
                  </div>

                  <h4>{selectedMetal}</h4>

                  <div className="img_div">
                    <img src={circle} alt="img" />

                    <div className="quality_content">
                      <div className="color_content">
                        <div
                          className="color_box"
                          style={{ backgroundColor: "#F0E68C" }}
                        ></div>
                        <h5>58.5% Gold</h5>
                      </div>
                      <div className="color_content">
                        <div
                          className="color_box"
                          style={{ backgroundColor: "#BAC4C8" }}
                        ></div>
                        <h5>8.7% Zinc</h5>
                      </div>
                    </div>

                    <div className="quality_content">
                      <div className="color_content">
                        <div
                          className="color_box"
                          style={{ backgroundColor: "#B87333" }}
                        ></div>
                        <h5>25.4% Copper</h5>
                      </div>
                      <div className="color_content">
                        <div
                          className="color_box"
                          style={{ backgroundColor: "#BDBAAE" }}
                        ></div>
                        <h5>7.3% Nickel</h5>
                      </div>
                    </div>
                  </div>
                  <div>
                    <p className="para">
                      The secret sauce that makes this piece.
                    </p>
                    <p className="para">
                      *All white gold pieces are Rhodium plated
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="acc_div">
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <div className="heading">
                  <img
                    src={ringwithdiamond}
                    alt="img"
                    style={{ width: "25px" }}
                  />

                  <h5>Wedding Band Details</h5>
                </div>
              </AccordionSummary>

             <AccordionDetails>
                            <div className="heading">
                              <h5>Details</h5>
                            </div>
                            <table>
                              <tr>
                                <td>SKU</td>
                                <td>316Q-WB-4-HA-WG-14</td>
                              </tr>
                              <tr>
                                <td>Carat</td>
                                <td>{selectedCarat}</td>
                              </tr>
                              <tr>
                                <td>Material</td>
                                <td>{selectedMetal}</td>
                              </tr>
            
                              <tr>
                                <td>Type</td>
                                <td>Hammered</td>
                              </tr>
                            </table>
                          </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <div className="heading">
                  <img src={ship} alt="img" />

                  <h5>Shipping</h5>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <p className="description">
                  This item is made to order and takes 2-3 weeks to craft. We
                  ship FedEx Priority Overnight, signature required and fully
                  insured.
                </p>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <div className="heading">
                  <img
                    src={ret}
                    alt="img"
                    style={{ width: "20px", height: "20px" }}
                  />

                  <h5>Return Policy</h5>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <p className="description">
                  Received an item you don't like? We are proud to offer free
                  returns within 30 days from receiving your item. Contact our
                  support team to issue a return.
                </p>
              </AccordionDetails>
            </Accordion>
          </div>

          <div className="appointment">
            <h5>Virtual Appointment</h5>
            <p>
              <strong>See jewelry</strong> up close with a personal appointment.
              Explore engagement rings, diamonds, and fine jewelry in person
              through your device.
            </p>
          </div>
        </div>
      </div>
    </Root>
  );
}
